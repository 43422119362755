import { Dense_button_dark, Dense_Button_Light } from "../components/buttons";
import Footer from "../components/footer";
import Navbar from "../components/navigations";
import TeamMemberCard from "../components/teamCard";
import TestimonialsSection from "../components/testimonials-section";

export default function Team() {
  document.title = "Team | Medspa Network";

  return (
    <>
      <Navbar type="" />
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Team</div>
          <div className="header-xxl pad-top-m">Expert Team Dedicated</div>
          <div className="header-xxl light">to Your Professional Journey.</div>
          <p className="body-l pad-top-m">
            We are committed to making a difference in the lives of our clients
            and their patients by providing the best possible care through
            superior educational events and innovative training programs.
          </p>
          <div className="button-group">
            <Dense_button_dark text="More About Us" link={"/about"} />
            <Dense_Button_Light text="View Events" link="/events" />
          </div>
          <img
            src={"/content/staff/resized/squadEdit.webp"}
            loading="lazy"
            alt=""
            className="full-video"
          />
        </div>
      </section>
      <section className="white-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-l">The MedSpa Network Faculty.</div>
          <div className="header-l light">
            Our Medical Practitioners are Board-Certified Experts in Medical
            Aesthetics, and our Business Professionals Excel at Running
            Client-Focused MedSpas.
          </div>
          <div className="staff-card-container slider-container pad-top-m">
            <TeamMemberCard
              name="Dr.Alan Durkin, M.D., M.S."
              credential="Plastic Surgeon"
              image={"/content/staff/resized/durkin-square.webp"}
              socials={{
                linkedin: "#",
                instagram: "#",
              }}
              bio={[
                "Dr. Alan Durkin is a Board Certified Plastic & Reconstructive Surgeon and owner of Ocean Drive Plastic Surgery, a private surgery center that combines style and elegance with a state-of-the-art surgical facility in a warm and welcoming office environment.When choosing Dr. Alan Durkin for cosmetic surgery, you will enjoy the privacy of overnight suites, VIP entrances, personalized medical attention, house calls, and private duty nursing. Furthermore, the center provides patients with continuity of care through the centers Medical Spa, Laser Facility, and Make-up Studio.",
                "Dr. Alan Durkin is both an American Board Certified Surgeon and American Board Certified Plastic & Reconstructive Surgeon. Dr. Durkin specializes in Plastic Surgery and Cosmetic Reconstructive Surgery of the face and breast including, facelifts and brow lifts, nose reshaping, facial fat grafting, breast augmentations, breast reconstruction and revisional breast surgery (fixing breast augmentations). Dr. Alan Durkin also routinely performs body contouring procedures such as tummy tucks and mommy makeovers (combination of breast surgery and tummy tuck).",
              ]}
            />
            <TeamMemberCard
              name="Stephanie Bieber"
              credential="APRN"
              image={"/content/staff/resized/stephanie-square.webp"}
              socials={{
                linkedin: "#",
                instagram: "#",
              }}
              bio={[
                "Stephanie Bieber's multigenerational family has deep roots in Vero Beach, which she calls home. Starting her medical journey as a nursing assistant in the ICU at the former Indian River Medical Center in 1997, Stephanie's view of medicine as a calling rather than a career has always guided her. The privilege of serving others resonates deeply with her, matched by her pursuit of excellence in her professional endeavors. Becoming an RN in 1999, her time in the cardiac catheterization laboratory profoundly impacted her, emphasizing the power of minimally invasive interventions over diagnostics.",
                "Graduating Magna Cum Laude in 2009 from the University of Central Florida Advanced Practice Nurse Practitioner program, Stephanie's humility was evident through her election to the Sigma Theta Tau Nursing Honor Society, where she remains an active member. Having spent 14 years as an advanced practice nurse at Holmes Regional Medical Center's Emergency Department, she broadened the practice by performing advanced procedures and mentoring incoming providers.",
                "Stephanie's journey took a personal turn with an injectable-based therapy that transformed her self-image and confidence, fueling her desire to enhance patients' life experiences. As part of the Ocean Drive team, her vast experience in laser resurfacing, neuromodulators, dermal fillers, and body contouring is matched by her enthusiasm to deliver top-quality outcomes in a secure, luxurious setting under the guidance of Drs. Durkin and Chim. Her ultimate goal remains to elevate her patients' lives through her expertise, will, and resources.",
              ]}
            />
            <TeamMemberCard
              name="Nick Woltjen"
              credential="PA-C"
              image={require("../assets/images/staff/nick_woltjen.jpg")}
              socials={{
                linkedin: "#",
                instagram: "#",
              }}
              bio={[
                "Nick Woltjen, a certified Physician Assistant with expertise in cosmetic dermatology, boasts a unique skill set forged through managing intricate skin conditions. His proficiency spans skin cancer assessments, prevention, and surgical/medical interventions, extending to premalignant lesions, rashes, psoriasis, and lupus. Advanced non-surgical hair loss remedies, including laser and PRP therapies, are also his forte.",
                "Heading the Ocean Drive Plastic Surgery's thriving medical and cosmetic dermatology practice, Nick seamlessly blends plastic surgery with aesthetics. With over a decade of experience in a high-volume cosmetic setting, he's a preeminent figure in specialized cosmetic lasers like fractional/non-fractional lasers, hybridized lasers, vascular lasers, and broadband light. Nick's pioneering approach combines laser treatments with injectable fillers, promising improved results and quicker recovery.",
                "Nick's teaching style involves comprehensive laser application explanations, ensuring learners grasp capabilities and limits. Prioritizing patients, he underscores relationship building, enabling trainees to provide information and care that foster connections. Nick's mission is to deeply embed laser knowledge, benefiting practices and patients alike.",
                "Hailing from Pennsylvania, Nick holds a mathematics/computer science degree from Robert Morris University and an M.S. in information systems management. Aiming for a more personable vocation, he pursued a second Master's in physician assistant studies at Chatham University, inspired by his physician grandfather's legacy. During this tenure, Nick's fervor for addressing skin issues and cancer took firm hold.",
              ]}
            />
            <TeamMemberCard
              name="Keaton Benning"
              credential="Director of Technology"
              image={"/content/staff/resized/keaton-square.webp"}
              socials={{
                linkedin: "#",
                instagram: "#",
              }}
              bio={[
                "Keaton Benning is a multi-talented individual with a unique blend of technical prowess and creative vision. In 2010, Keaton embarked on a journey into the world of Nuclear Electrical Engineering, laying the foundation for a future marked by innovation and curiosity. Although the formal path was left incomplete, the passion for exploration persisted.",
                "Transitioning to a different realm, Keaton delved into Art and Visual Effects, studying at Digital Domain. This shift in focus unveiled a profound talent for creativity and a deep appreciation for the intersection of technology and aesthetics.",
                "With a versatile skill set that spans programming languages like C++, JavaScript, and Python, Keaton founded an app development company, harnessing the power of technology to bring ideas to life. This entrepreneurial spirit thrives in Keaton's vision for the future.",
                "Keaton's aspiration to develop groundbreaking medical technologies has driven their dedication to the aesthetics industry for the past four years. This industry experience has provided valuable insights into the intersection of beauty and technology, which they are eager to leverage in their future endeavors.",
                "Keaton Benning represents the embodiment of curiosity, creativity, and technical acumen. Their journey is a testament to the idea that passion and diverse experiences can converge to shape an innovative future.",
              ]}
            />
            <TeamMemberCard
              name="Tateum Valentine"
              credential="Operations Manager"
              image={"/content/staff/resized/TATEUM-square-2.webp"}
              socials={{
                linkedin: "#",
                instagram: "#",
              }}
              bio={[
                "Tateum holds a Bachelor's Degree in Communication Studies from Towson University and brings over five years of experience in medical aesthetics. With a specialty in spa management, she has excelled as a leader within the industry, serving on a leadership committee and managing two high-performing locations in New York and New Jersey. During her tenure, she developed and implemented successful protocols and strategies, driving sales growth and operational efficiency across both locations.",
                "Tateum's passion for medical aesthetics is evident in her dedication to collaborating with skilled medical professionals, staying informed about the latest treatments and products, and continually advancing her expertise in this rapidly evolving field.",
              ]}
            />
          </div>
        </div>
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-l pad-top-m">Training Faculty</div>
          <div className="staff-card-container slider-container pad-top-m">
            <TeamMemberCard
              name="Trevor Larson"
              credential="RN"
              image={"/content/staff/Trevor_crop.png"}
              socials={{
                linkedin: "#",
                facebook: "trubeautybytrevor",
                instagram: "trubeautybytrevor/",
              }}
              bio={[
                "Trevor is an elite injector of Tru Beauty by Trevor, a luxury cosmetic injectables practice and Med Spa. Trevor has a decade of experience working with injectables, first being PALETTE® trained and certified in 2011. His many online reviews are glowing, and patients praise his bedside manner, attention to detail and his love and care for each and every single person that walk through the doors. Trevor truly loves what he does and is passionate about the art and science of injectables. His motto is that the experience is as important as the outcome –  we put just as much focus on making our patients feel good as we do on looking good, both on the inside and the outside.",
                "Trevor was born in Southern Utah and grew up in Sioux Falls, South Dakota. He earned his Registered Nursing degree in Sioux Falls and has worked in the medical field for 15 years in Trauma and Emergency Room medicine, Flight Medicine, Interventional Radiology, Operating Room and Recovery, ICU/Pulmonary medicine, and now exclusively as a cosmetic injector for the past 4 years.",
                "He moved to Las Vegas in 2011 and has been a proud Nevada resident ever since!",
                "When he is not in office seeing patients, he spends his time with his four beautiful children and his amazing family.",
                "Bella, his late wife, was the Managing Partner and Director of Marketing for Tru Beauty by Trevor until she unexpectedly passed in March of 2022. Trevor and Bella’s dream was to give love, compassion, and connection which served as the foundation of the work they did together each day. Trevor continues to cherish and build this dream they always desired for his family.",
              ]}
            />
            <TeamMemberCard
              name="Katie Robinson"
              credential="APRN"
              image={"/content/staff/katie_robinson.jpg"}
              socials={{
                linkedin: "katierobinson20202022",
                instagram: "4esthetics_lounge",
                facebook: "katie.bishop.9?mibextid=LQQJ4d",
              }}
              bio={[
                "Katie Robinson is an Advanced Practice Registered Nurse (APRN) board certified in Family Medicine with six years of clinical expertise. She has a diverse background in aesthetic and functional medicine, having spent the past five years specializing in aesthetic medicine and the past year focusing on functional medicine approaches. ",
                " Katie is the owner of 4esthetics Lounge MedSpa in Lexington, Kentucky, where she combines her passion for enhancing patient well-being with her commitment to patient safety and results. In addition to her clinical work, Katie serves as an independent aesthetic trainer, sharing her expertise with fellow practitioners and promoting best practices in the field. ",
                "As President of the Kentucky Association of Medical Aesthetic Providers (KAMAP), Katie leads a non-profit organization dedicated to unifying the medical aesthetics community across Kentucky. Under her leadership, KAMAP champions collaboration over competition and prioritizes patient safety, education, and excellence in care. ",
                "Outside of her professional life, Katie is a dedicated wife and mother to two young sons, ages 2 and 6. She enjoys traveling and unwinding with a relaxing spa day, balancing her career ambitions with her love for family and personal wellness.",
              ]}
            />
            <TeamMemberCard
              name="Brandi Gregge"
              credential="FNP-BC"
              image={"/content/staff/Gregg-headshot.png"}
              socials={{
                linkedin: "brandi-gregge/",
                instagram: "theblondeclinician/",
              }}
              bio={[
                "I’m Brandi Gregge, MSN, FNP-BC, Founder, Trainer, & Aesthetic Injector of Mint & Needle™. Armed with a Master's in Nursing, a B.S. in Kinesiology and Nursing, as well as an MBA, I leveraged my business acumen and 10+ years of medical and aesthetics experience as a nurse practitioner to establish two Mint & Needle locations in Delaware. With a steadfast diligence toward health, safety, and quality, I believe the traditional weekend training courses are not enough in regenerative aesthetic medicine. I created a rigorous internal residency in which all Mint & Needle injectors must pass before working with patients, in addition to completing ongoing education throughout their career. ",
                "Passionate about helping men and women meet their health and aesthetics goals, my role expands beyond Mint & Needle, as a national influencer, leader, speaker, and selected trainer for Allergan, Cartessa Aesthetics, Merz, and other pharmaceutical companies.",
                "My passion for health and aesthetics began early in life. Coming from a single-parent, resource-restricted environment, I learned the importance of hustle and looking your best to feel your best early on. Medicine and becoming a nurse practitioner initially appealed to me because of health-related issues that hit my family throughout my adolescence. ",
                "My excitement for aesthetic medicine grew as I witnessed the impact aesthetic medicine has — not just on the outward appearance of my patients, but also for the internal transformations as a result. This realization led me to build an aesthetic medicine practice focused on natural results with a wellness mindset. ",
              ]}
            />
            <TeamMemberCard
              name="Akash Chandawarkar"
              credential="Plastic Surgeon"
              image={"/content/staff/akash.jpg"}
              socials={{
                linkedin: "#",
                instagram: "dr.akashplasticsurgery/",
              }}
              bio={[
                "Dr. Akash Chandawarkar is a Harvard educated physician, Johns Hopkins trained plastic surgeon, and fellowship trained aesthetic surgeon who is excited to combine his prestigious training and innovative vision on the cutting edge of the plastic surgery to deliver the full range of patient-centered cosmetic treatments and surgeries.",
                "Dr. Akash Chandawarkar is the son of immigrant parents from India who both moved to America in pursuit of better educational opportunities. As a result, from a young age, the importance of education and work ethic was instilled in him while growing up on the New Jersey shore. Dr. Chandawarkar has chased the best education all over the country to hone his craft. ",
                "Dr. Chandawarkar grew up in central New Jersey and attended the highly competitive Science & Engineering magnet program at Manalapan High School, where he served as class president. He studied neuroscience and nuclear engineering at the Massachusetts Institute of Technology (MIT) for his undergraduate studies, and also was class president. He stayed in Boston for his medical studies as one of only thirty students admitted to the joint Harvard-MIT Division of Health Sciences & Technology (HST) program at Harvard Medical School. During his time there, he worked with the first face and hand transplantation patients as a research fellow for a year at the Brigham and Women’s Hospital. This experience showed Dr. Chandawarkar the power of restorative and reconstructive surgery, and was where his passion for plastic surgery first blossomed. He earned his M.D. degree (Honors in a Special Field) with cum laude distinction from Harvard Medical School in 2014. He also served as student body president at Harvard.",
                "He completed a seven-year integrated plastic and reconstructive surgery residency at the world-renowned Johns Hopkins/University of Maryland program. During that time, he spent time learning complex cancer and trauma reconstruction at Johns Hopkins Hospital, craniofacial reconstruction at the R Adams Cowley Shock Trauma Center, and hand surgery at the Curtis National Hand Center. He also learned aesthetic and cosmetic surgery locally in the Washington, D.C. metro region, and spent extra elective time visiting the top plastic surgeons in Beverly Hills, Laguna Beach, San Diego, and Dallas.",
                "Dr. Chandawarkar has always been interested in the newest technologies and devices, and took a year off from residency to move to the west coast to Silicon Valley. He was one of only twelve fellows at the Stanford University Biodesign Innovation Fellowship in Palo Alto, CA, where he worked with medical device experts to learn the full life cycle of turning unmet needs into patient solutions. He returned to Baltimore to finish his residency training at Johns Hopkins.",
                "Dr. Chandawarkar is one of few plastic surgery residency graduates to undertake further aesthetic training after residency in an Aesthetic Society endorsed one-year fellowship. He moved to New York City to work with over 150 Upper East Side aesthetic surgeons at the Manhattan Eye, Ear and Throat Hospital (MEETH). The MEETH fellowship only accepts 2 fellows each year and is the most prestigious and historic training program for aesthetic surgeons in the world, with direct mentorship from many giants in the field of facial and rhinoplasty surgery.",
              ]}
            />
            <TeamMemberCard
              name="Deborah Hudak"
              credential="Oculofacial Surgeon"
              image={"/content/staff/hudak.webp"}
              socials={{
                linkedin:
                  "all-about-aesthetics-pc-dba-deborah-kessler-hudak-md-mha/",
                instagram: "#",
              }}
              bio={[
                "Dr. Hudak, is a Board-Certified Ophthalmologist, Oculofacial Surgeon, and Member of the American Academy of Ophthalmology, American Academy of Cosmetic Surgery, and Physician Leaders of the World.",
                "She integrates enthusiasm, passion, and artistic flair in all her endeavors. A former Internist and Emergency Room physician, she is known by her patients for her thoroughness, caring, and compassion.",
                "Her undergraduate and graduate school studies were in chemical engineering, biochemistry, and microbiology. She obtained her medical degree from the University of Cincinnati and then underwent training in Internal Medicine at Kettering Medical Center.",
                "While working as a Teaching Staff member at Washington University School of Medicine in St. Louis, MO, she was a Research Fellow in molecular virology. For years she worked as an Emergency Room physician before undergoing an Ophthalmology residency at St. Louis University and then began studies under many of this nation’s greatest surgeons to become a facial plastic surgeon.",
                "She is especially considered an expert in eyelid and brow procedures at her solo practice in Raleigh. Additionally, Dr. Kessler Hudak is a Key Opinion Leader, and one of the most requested aesthetic injectables instructors for Basic, Advanced, and Master’s level courses for Aesthetic Medical Educator Training, for which she travels extensively.",
                "Dr. Hudak’s greatest inspirations came from her mother, a Nurse and concert pianist-in-training (she was playing Rachmaninoff at 5 years of age), her grandmother, a professional ballerina and painter of modern art, and her grandfather, an Ophthalmologist and photographer. With the blend of Medicine and Art talents in the people she loved most, it is small wonder that Medicine and Beauty are passions of hers.",
                "She is a strong advocate for healthy skin and preventive health measures and has special interests in facial rejuvenation and anti-aging medicine and procedures. Dr. Hudak is a member of the American Academy of Cosmetic Surgery, American Academy of Anti-Aging Medicine, and American Academy of Ophthalmology. She is very conscientious, compassionate, and detail-oriented. She loves teaching patients how to care for their skin and bodies, and is very enthusiastic to help people feel healthy and more self-confident about themselves.",
                "She is noted by her peers for her superb surgical skills, attention to detail, and diligence in pursuing education in the newest procedures and research.",
              ]}
            />
            <TeamMemberCard
              name="Patrine Fardette"
              credential="PA-C"
              image={"/content/staff/patrine_fardette.png"}
              socials={{
                linkedin: "face-it-aesthetics-fl/",
                instagram: "nordicinjector/",
              }}
              bio={[
                "As the owner of Face It Aesthetics, I am passionate about helping patients improve their look and confidence, and excited to share my knowledge with you. I am a Certified Physician Assistant Injector with over 24 years of experience, currently specializing in facial sculpting with fillers and neuromodulators. My work demands equal parts artistry and skill, along with excellent knowledge of facial anatomy and an eye for facial aesthetics, because every patient is unique.",
                "In addition to my surgical experience in plastic surgery, spine surgery, operating rooms and critical care units, I have been trained on advanced aesthetic treatments by plastic surgeons and dermatology experts. I’m certified in advanced fillers, laser techniques and skin tightening, and attend industry workshops and continuing education to stay current on new products, services, and techniques.",
                "Before founding Face It Aesthetics, I was director of the Perioperative Risk Management Program at Cantor Spine Institute. I am a member of both the American Academy of Physician Assistants (AAPA) and Florida Academy of Physician Assistants (FAPA). I graduated with honors from Nova Southeastern University in 1997.",
                "When I’m not at work, I’m living the mermaid life, boating and spending time with my husband and two young sons.",
              ]}
            />
            <TeamMemberCard
              name="Rachel Polazzi"
              credential="CRNA"
              image={"/content/staff/rachel-polazzi2-min.webp"}
              socials={{
                linkedin: "#",
                instagram: "#",
              }}
              bio={[""]}
            />
            <TeamMemberCard
              name="Miranda Webb"
              credential="CRNA"
              image={"/content/staff/miranda_webb.webp"}
              socials={{
                linkedin: "#",
                instagram: "#",
              }}
              bio={[
                "Miranda “Mimi” Webb is a dual-degree Registered Nurse and Certified Nurse Injector with three years of experience in Aesthetic Medicine. Currently serving the Philadelphia area with her knowledge and expertise in neurotoxins, dermal fillers, PDO Threads, PLLA Threads, non-surgical rhinoplasty, and much more. As one of Mimi’s patients,  you can expect to receive an experience encompassing not only leading non-surgical techniques and a unique, personalized plan to achieve your aesthetic goals, but also compassionate, honest, and comprehensive care. Mimi is dedicated to serving your self-image as a whole, leaving you looking and feeling better with each encounter.",
              ]}
            />
            <TeamMemberCard
              name="Amanda Murray"
              credential="CRNA"
              image={"/content/staff/amanda_murray.png"}
              socials={{
                linkedin: "#",
                instagram: "beautiphiyou/",
              }}
              bio={[
                "Amanda is a certified registered nurse anesthetist who has been in the nursing profession since 2007. She did her injector training under Kelly Hermans and Beautiphi Academy. Since then, she has attended many advanced trainings with Galderma, Merz, and Revance Aesthetics. She’s passionate about helping her patients feel beautiful. Using her anesthesia and nursing backgrounds, she gives the most comprehensive assessments and treatment plans possible. She specializes in anti-wrinkle injections, dermal fillers, chemical peels, microneedling, CO2 skin resurfacing, and Subnovii plasma pen treatments.",
                "Amanda loves to provide convenience to people who desire injections in the comfort of their own homes. If you’re in the Clarkston or Lake Orion area and are looking for aesthetic services or want to have an injectables party with your friends, Amanda is your girl! Ready to start your aesthetic journey at Beautiphi? Book a complimentary cosmetic consultation with Amanda. She looks forward to meeting you and helping you become a more refreshed, natural, beautiful version of yourself.",
              ]}
            />
            <TeamMemberCard
              name="Kyle Farr"
              credential="DNP, NP-C"
              image={"/content/staff/Kyle_cropped.jpeg"}
              socials={{
                linkedin: "#",
                instagram: "kylefarraesthetics/",
              }}
              bio={[
                "Dr. Kyle Farr, DNP, NP-C is a Nurse Practitioner, born and raised in Michigan. Kyle is a Board Certified Nurse Practitioner in Family Practice (FNP) and he specializes in aesthetic and wellness medicine.",
                "Kyle has extensive didactic and clinical training in aesthetic, cosmetic, and wellness medicine. Throughout his career, and ongoing, he has trained alongside some of the leading experts in aesthetic and cosmetic medicine. Kyle is consistently advancing his education in medical aesthetics and cosmeceuticals to provide the most up-to-date care for his patients. Kyle routinely trains others all over the country in aesthetic techniques and product education. Currently, Kyle is proud to be both a member of the Galderma GenNOW faculty and a GurU Educator/Trainer for Revance Aesthetics. ",
                "In clinical practice, Kyle loves working with his patients to help them maintain their aesthetic health and wellbeing using a patient-centered approach. He works with the patient to set achievable goals that will leave them looking naturally refreshed. The foundation of Kyle’s artistry is that of facial harmonization. In short, he assesses and treats the face in its entirety utilizing carefully curated techniques. This delicately crafted process brings complete harmonization of your facial features and leaves you looking like YOU.",
              ]}
            />
          </div>
        </div>
      </section>
      <section className="light-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline">Our Values</div>
          <div className="header-xl pad-top-l">
            When you Choose us, you're Choosing
          </div>
          <div className="header-xl light">
            a Partner Committed to Your Success and Excellence.
          </div>
          <p className="body-l pad-top-m">
            Our principles ensure you receive the highest quality care and
            support throughout your professional journey.
          </p>
          <div
            id="w-node-_38632b1b-bb33-f969-1fd8-67b44ffe3394-1d55edf2"
            className="w-layout-layout full-width-stack pad-top-l wf-layout-layout"
          >
            <div
              id="w-node-_1edc862f-3af9-450e-9586-9f61aebfcf72-1d55edf2"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Patient Safety</div>
              <p className="body-m pad-top-m">
                Your well-being is our top priority. We adhere to the highest
                safety standards in all our procedures and training sessions,
                ensuring you can trust the quality of care you receive.
              </p>
            </div>
            <div
              id="w-node-_1edc862f-3af9-450e-9586-9f61aebfcf79-1d55edf2"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Integrity &amp; Transparency</div>
              <p className="body-m pad-top-m">
                Honesty and openness are at the heart of our operations. We
                maintain high ethical standards and provide transparent
                communication, so you feel confident and informed at every step.
              </p>
            </div>
            <div
              id="w-node-_1edc862f-3af9-450e-9586-9f61aebfcf80-1d55edf2"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">
                Continuous <br />
                Education
              </div>
              <p className="body-m pad-top-m">
                We stay at the forefront of aesthetic medicine through ongoing
                learning and innovation. Our commitment to the latest
                advancements ensures you receive the most current techniques and
                knowledge.
              </p>
            </div>
            <div
              id="w-node-_1edc862f-3af9-450e-9586-9f61aebfcf87-1d55edf2"
              className="w-layout-cell featured-item"
            >
              <div className="header-m">Collaboration &amp; Support</div>
              <p className="body-m pad-top-m">
                We go beyond training; we are your partners in growth. Our
                collaborative approach provides ongoing support to help you
                achieve your professional goals and elevate your practice.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="dark-background-section">
        <div className="w-layout-blockcontainer container left-justify ">
          <div className="header-xs title-underline light">
            Get Started Today
          </div>
          <div className="header-l pad-top-m">
            Get Your Membership and Take the Next Step in Your Career.
          </div>
          <div className="header-l light">
            Stay Ahead in the Fast-Evolving Field of Aesthetic Medicine,
            Ensuring you Have Skills, Knowledge, and Connections Needed to
            Excel.
          </div>
          <div className="button-group">
            <Dense_Button_Light text="Join us Today" link="/membership" />
            <Dense_button_dark text="More About Us" link={"/about"} />
          </div>
        </div>
      </section>
      <TestimonialsSection />
      <Footer />
    </>
  );
}
