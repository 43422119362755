import Footer from "../components/footer";
import Navbar from "../components/navigations";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";

export default function GlpSignup() {
  const [formData, setFormData] = useState({
    signatureDate: new Date().toLocaleDateString(),
  });
  const [PDF, setPDF] = useState(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const success = queryParams.get("success");
  const myRef = useRef(null);
  const orderRef = useRef(null);

  async function fillPDF(e) {
    e.preventDefault();
    document.getElementById("submit-semaglutide-form").disabled = true;
    document.getElementById("submit-semaglutide-form").innerText =
      "Submitting...";

    if (formData.billingState == "MS" || formData.billingState == "WV") {
      alert(
        "We are sorry, but we are currently not accepting applications from  Mississippi, or West Virginia. Please contact us at keaton@medspanetwork.com for more information."
      );
      document.getElementById("submit-semaglutide-form").innerText =
        "Cannot Submit Form";
      return;
    } else {
      document.getElementById("submit-semaglutide-form").disabled = true;
      document.getElementById("submit-semaglutide-form").innerText =
        "Submitting form...";

      axios
        .post("/api/v2/emails/semaglutide-pdf", formData, {
          headers: {
            "X-Content-Type": "application/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "success") {
            navigate("/semaglutideSignup?success=true");
          } else {
            console.log(res.data.message);
            document.getElementById("submit-semaglutide-form").disabled = false;
            document.getElementById("submit-semaglutide-form").innerText =
              "Form Submission Failed, please try again.";
          }
        })
        .catch((err) => {
          console.log(err);
          document.getElementById("submit-semaglutide-form").disabled = false;
          document.getElementById("submit-semaglutide-form").innerText =
            "Form Submission Failed, please try again.";
        });
    }
  }

  return (
    <div>
      <Navbar />
      <section
        className="dark-background-section image"
        style={{
          backgroundImage: `linear-gradient( rgba(13, 14, 70, 0.78), rgba(11, 9, 61, 0.69) ), url(${require("../assets/images/semaglutide-background.png")})`,
          height: "auto",
          minHeight: "60vh",
        }}
      >
        <div
          className="w-layout-blockcontainer container gap-m "
          style={{ overflow: "visible" }}
        >
          <div className="header-l center">
            Fill out the form below to get started!
          </div>
          <div className="header-xs light" style={{ textAlign: "center" }}>
            MedSpa Network provides medical practices and professionals with a
            complete GLP program, offering streamlined access to GLP-1
            medications, expert training, and dedicated support. Our program is
            designed to help seamlessly integrate these advanced treatments into
            your practice, empowering providers to deliver effective,
            cutting-edge care to their patients.
            <br />
          </div>
        </div>
      </section>
      {success ? (
        <section className="white-background-section">
          <div
            style={{
              width: "100%",
              padding: 20,
              backgroundColor: "lightgreen",
            }}
          >
            <h1 style={{ color: "green" }}>Form Submitted Successfully</h1>
          </div>
        </section>
      ) : (
        <section className="dark-background-section" ref={orderRef}>
          <div className="w-layout-blockcontainer container w-container">
            <form
              id="email-form-2"
              name="email-form-2"
              data-name="Email Form 2"
              method="get"
              className="form-2 register-form"
              data-wf-page-id="6508535901706168d08ad083"
              data-wf-element-id="d90ffdf5-d06a-509c-21eb-2a59bbf09d6e"
              onSubmit={(e) => {
                fillPDF(e);
              }}
            >
              <h1 className="header-l ">Office Information</h1>
              <div className="register-field-block">
                <label htmlFor="officeName" className="register-field-heading">
                  Office Name
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="officeName"
                  name="officeName"
                  placeholder={
                    formData.officeName ? formData.officeName : "Office Name"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value.replaceAll("/", "_"),
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="doctorsName" className="register-field-heading">
                  Doctor's Name
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="doctorsName"
                  name="doctorsName"
                  placeholder={
                    formData.doctorsName
                      ? formData.doctorsName
                      : "Doctor's Name"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="address" className="register-field-heading">
                  Address
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="address"
                  name="address"
                  placeholder={formData.address ? formData.address : "Address"}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="doctorsPhone"
                  className="register-field-heading"
                >
                  Doctor's Phone
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="doctorsPhone"
                  name="doctorsPhone"
                  placeholder={
                    formData.doctorsPhone
                      ? formData.doctorsPhone
                      : "Doctor's Phone"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="npi" className="register-field-heading">
                  NPI #
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="npi"
                  name="npi"
                  placeholder={formData.npi ? formData.npi : "NPI"}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="fax" className="register-field-heading">
                  Fax
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="fax"
                  name="fax"
                  placeholder={formData.fax ? formData.fax : "Fax"}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="dea" className="register-field-heading">
                  DEA #
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="dea"
                  name="dea"
                  placeholder={formData.dea ? formData.dea : "DEA"}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <h1 className="header-l pad-top-m">License Information</h1>
              <div className="register-field-block">
                <label
                  htmlFor="stateMedicalLicense"
                  className="register-field-heading"
                >
                  State License Number
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="stateMedicalLicense"
                  name="stateMedicalLicense"
                  placeholder={
                    formData.stateMedicalLicense
                      ? formData.stateMedicalLicense
                      : "State License Number"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="stateOfLicense"
                  className="register-field-heading"
                >
                  State of License
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="2"
                  data-name="stateOfLicense"
                  name="stateOfLicense"
                  placeholder={
                    formData.stateOfLicense
                      ? formData.stateOfLicense
                      : "Two digit State Code ( FL )"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="licenseExpDate"
                  className="register-field-heading"
                >
                  License Expiration
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="5"
                  data-name="licenseExpDate"
                  name="licenseExpDate"
                  placeholder={
                    formData.licenseExpDate
                      ? formData.licenseExpDate
                      : "License Expiration MM/YY"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="doctorsEmail"
                  className="register-field-heading"
                >
                  Doctor's Email
                </label>
                <input
                  required
                  type="email"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="doctorsEmail"
                  name="doctorsEmail"
                  placeholder={
                    formData.doctorsEmail
                      ? formData.doctorsEmail
                      : "Doctor's Email"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <h1 className="header-l pad-top-m">Clinic Contact</h1>
              <div className="register-field-block">
                <label
                  htmlFor="clinicContact"
                  className="register-field-heading"
                >
                  Clinic Contact
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="clinicContact"
                  name="clinicContact"
                  placeholder={
                    formData.clinicContact
                      ? formData.clinicContact
                      : "Clinic Contact"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="clinicContactEmail"
                  className="register-field-heading"
                >
                  Clinic Contact Email
                </label>
                <input
                  required
                  type="email"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="clinicContactEmail"
                  name="clinicContactEmail"
                  placeholder={
                    formData.clinicContactEmail
                      ? formData.clinicContactEmail
                      : "Clinic Contact Email"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="clinicContactPhone"
                  className="register-field-heading"
                >
                  Phone
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="clinicContactPhone"
                  name="clinicContactPhone"
                  placeholder={
                    formData.clinicContactPhone
                      ? formData.clinicContactPhone
                      : "Phone"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="practiceSpecialty"
                  className="register-field-heading"
                >
                  Practice Specialty
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="practiceSpecialty"
                  name="practiceSpecialty"
                  placeholder={
                    formData.practiceSpecialty
                      ? formData.practiceSpecialty
                      : "Practice Specialty"
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <h1 className="header-l pad-top-m">
                Billing Location Information
              </h1>
              <div className="register-field-block">
                <label
                  htmlFor="billingStreetAddress"
                  className="register-field-heading"
                >
                  Billing Street Address
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="billingStreetAddress"
                  name="billingStreetAddress"
                  placeholder="Billing Street Address"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="billingCity" className="register-field-heading">
                  Billing City
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="billingCity"
                  name="billingCity"
                  placeholder="Billing City"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="billingState"
                  className="register-field-heading"
                >
                  Billing State
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="2"
                  data-name="billingState"
                  name="billingState"
                  placeholder="Two digit billing state code ( FL )"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value.toUpperCase(),
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="billingZipCode"
                  className="register-field-heading"
                >
                  Billing Zip Code
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="billingZipCode"
                  name="billingZipCode"
                  placeholder="Billing Zip Code"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="billingPhoneNumber"
                  className="register-field-heading"
                >
                  Billing Phone Number
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="billingPhoneNumber"
                  name="billingPhoneNumber"
                  placeholder="Billing Phone Number"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <h1 className="header-l pad-top-m">Shipping Information</h1>
              <div
                className="register-field-block"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  style={{ width: 25, height: 25 }}
                  onChange={(e) => {
                    e.target.checked
                      ? setFormData((prevState) => ({
                          ...prevState,
                          shippingStreetAddress: formData.billingStreetAddress,
                          shippingCity: formData.billingCity,
                          shippingState: formData.billingState,
                          shippingZipCode: formData.billingZipCode,
                          shippingPhoneNumber: formData.billingPhoneNumber,
                        }))
                      : setFormData((prevState) => ({
                          ...prevState,
                          shippingStreetAddress: "",
                          shippingCity: "",
                          shippingState: "",
                          shippingZipCode: "",
                          shippingPhoneNumber: "",
                        }));
                  }}
                />
                <p style={{ padding: 0, margin: 0, fontSize: 18 }}>
                  Shipping address is same as billing address
                </p>
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="shippingStreetAddress"
                  className="register-field-heading"
                >
                  Shipping Street Address
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="shippingStreetAddress"
                  name="shippingStreetAddress"
                  value={
                    formData.shippingStreetAddress
                      ? formData.shippingStreetAddress
                      : ""
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="shippingCity"
                  className="register-field-heading"
                >
                  Shipping City
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="shippingCity"
                  name="shippingCity"
                  value={formData.shippingCity ? formData.shippingCity : ""}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="shippingState"
                  className="register-field-heading"
                >
                  Shipping State (FL)
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="2"
                  data-name="shippingState"
                  name="shippingState"
                  value={formData.shippingState ? formData.shippingState : ""}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value.toUpperCase(),
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="shippingZipCode"
                  className="register-field-heading"
                >
                  Shipping Zip Code
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="shippingZipCode"
                  name="shippingZipCode"
                  value={
                    formData.shippingZipCode ? formData.shippingZipCode : ""
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="shippingPhoneNumber"
                  className="register-field-heading"
                >
                  Shipping Phone Number
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="shippingPhoneNumber"
                  name="shippingPhoneNumber"
                  value={
                    formData.shippingPhoneNumber
                      ? formData.shippingPhoneNumber
                      : ""
                  }
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <h1 className="header-l pad-top-m">Credit Card Information</h1>
              <p>
                This information is used by The Pharmacy Hub once you start
                prescribing GLP.
              </p>
              <div className="register-field-block">
                <label htmlFor="nameOnCard" className="register-field-heading">
                  Name on Card
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="nameOnCard"
                  name="nameOnCard"
                  placeholder="Name on Card"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="typeOfCard" className="register-field-heading">
                  Type of Card
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="typeOfCard"
                  name="typeOfCard"
                  placeholder="Type of Card"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="cardNumber" className="register-field-heading">
                  Card Number
                </label>
                <input
                  required
                  type="number"
                  className="checkout-field w-input"
                  maxLength="20"
                  data-name="cardNumber"
                  name="cardNumber"
                  placeholder="Card Number"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="cardExpiration"
                  className="register-field-heading"
                >
                  Card Expiration
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="cardExpiration"
                  name="cardExpiration"
                  placeholder="MM/YY"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="cvv" className="register-field-heading">
                  CVV
                </label>
                <input
                  required
                  type="number"
                  className="checkout-field w-input"
                  maxLength="4"
                  data-name="cvv"
                  name="cvv"
                  placeholder="CVV"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <h1 className="header-l pad-top-m">Signature</h1>
              <div className="register-field-block">
                <label
                  htmlFor="signatureDate"
                  className="register-field-heading"
                >
                  Signature Agreement
                </label>
                <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                  <input
                    required
                    type="checkbox"
                    maxLength="256"
                    data-name="signatureDate"
                    name="signatureDate"
                    placeholder="Signature Date"
                  />
                  <p>
                    By clicking the checkbox I,{" "}
                    {formData.doctorsName
                      ? formData.doctorsName
                      : "UNKNOWN NAME"}
                    , agree that the typed name in the signature field will be
                    used as my Signature on the filled out PDF document that
                    will be sent to The Pharmacy Hub.
                  </p>
                </div>
              </div>
              <div className="register-field-block">
                <label htmlFor="signature" className="register-field-heading">
                  Signature
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="signature"
                  name="signature"
                  placeholder="Signature"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="register-field-block">
                <label
                  htmlFor="signatureDate"
                  className="register-field-heading"
                >
                  Signature Date
                </label>
                <input
                  type="text"
                  className="checkout-field w-input"
                  data-name="signatureDate"
                  name="signatureDate"
                  value={formData.signatureDate}
                />
              </div>

              <div className="register-field-block">
                <label htmlFor="printName" className="register-field-heading">
                  Print Name
                </label>
                <input
                  required
                  type="text"
                  className="checkout-field w-input"
                  maxLength="256"
                  data-name="printName"
                  name="printName"
                  placeholder="Print Name"
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <button
                id="submit-semaglutide-form"
                className="button-dense-primary-dark "
                type="submit"
              >
                Submit Form
              </button>
            </form>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}
