import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CreateAccount } from "../services/members";
import { useUserContext } from "../context/userContext";

export default function Signup() {
  const { loginUser } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    isAdmin: false,
    isTempPassword: false,
  });
  const [slide, setSlide] = useState(0);
  const [warning, setWarning] = useState(null);

  const slides = [
    {
      title: "Verify NPI",
      progress: 0,
      inputs: [
        {
          label: "NPI Number",
          type: "text",
          placeholder: "NPI Number",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, npiNumber: e.target.value });
          },
        },
      ],
      submitButton: {
        text: "Verify",
        onClick: () => {
          // Verify NPI
          changeSlide(1);
          // VerifyNPI();
        },
      },
    },
    {
      title: "Login Creation",
      progress: 20,
      inputs: [
        {
          label: "Email",
          type: "email",
          placeholder: "Email",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, email: e.target.value });
          },
        },
        {
          label: "Password",
          type: "password",
          placeholder: "Password",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, password: e.target.value });
          },
        },
      ],
      submitButton: {
        text: "Next",
        onClick: () => {
          // Verify NPI
          changeSlide(2);
        },
      },
    },
    {
      title: "User Creation",
      progress: 40,
      inputs: [
        {
          label: "firstName",
          type: "text",
          placeholder: "First Name",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, firstName: e.target.value });
          },
        },
        {
          label: "lastName",
          type: "text",
          placeholder: "Last Name",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, lastName: e.target.value });
          },
        },
        {
          label: "credential",
          type: "text",
          placeholder: "Credential (MD, PA-C, etc.)",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, credential: e.target.value });
          },
        },
        ,
        {
          label: "phoneNumber",
          type: "text",
          placeholder: "Your Phone Number",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, phoneNumber: e.target.value });
          },
        },
      ],
      submitButton: {
        text: "Next",
        onClick: () => {
          // Verify NPI
          changeSlide(3);
        },
      },
    },
    {
      title: "Company Information",
      progress: 60,
      inputs: [
        {
          label: "company",
          type: "text",
          placeholder: "Company Name",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, company: e.target.value });
          },
        },
        {
          label: "company Website",
          type: "text",
          placeholder: "Company Website",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, companyWebsite: e.target.value });
          },
        },
        {
          label: "companyAddress",
          type: "text",
          placeholder: "Company Address",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, companyAddress: e.target.value });
          },
        },
        {
          label: "companyCity",
          type: "text",
          placeholder: "Company City",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, companyCity: e.target.value });
          },
        },
        {
          label: "companyState",
          type: "text",
          placeholder: "Company State",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, companyState: e.target.value });
          },
        },
        ,
        {
          label: "companyZipCode",
          type: "text",
          placeholder: "Company Zip Code",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, companyZipCode: e.target.value });
          },
        },
      ],
      submitButton: {
        text: "Next",
        onClick: () => {
          // Verify NPI
          changeSlide(4);
        },
      },
    },
    {
      title: "Refferal Information",
      progress: 80,
      inputs: [
        {
          label: "referral",
          type: "text",
          placeholder: "How did you hear about us?",
          required: true,
          onChange: (e) => {
            // Check if email is valid
            setFormData({ ...formData, referral: e.target.value });
          },
        },
      ],
      submitButton: {
        text: "Submit",
        onClick: () => {
          createAccount();
        },
      },
    },
  ];

  async function createAccount() {
    const results = await CreateAccount(formData);
    console.log(results);
    if (results.status == "success") {
      localStorage.setItem("authToken", results.data._id);
      loginUser(results.data.user);
      localStorage.setItem("authToken", results.data.token);
      navigate("/successful-account-creation", {
        state: { message: "Payment Submitted", ...location.state },
      });
    } else {
      console.log(results.message);
      if (results.message === "Member already exists") {
        // navigate("/login");
      }
      alert(results.message);
    }
  }

  function VerifyNPI() {
    console.log(formData.npiNumber);
    axios
      .get(`/api/v2/Website/accounts/verify-npi?npi=${formData.npiNumber}`)
      .then((res) => {
        const data = res.data.results[0];
        setFormData((prevState) => ({
          ...prevState,
          npiData: { ...data },
        }));
        changeSlide(1);
        setWarning(null);
      })
      .catch((err) => {
        console.log(err);
        setWarning("Could not verify the NPI Number. Please try again.");
      });
  }

  function changeSlide(slideNumber) {
    //Clear all inputs
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input) => {
      input.value = "";
    });

    //Change Slide
    setSlide(slideNumber);

    //if input data already exists, fill in the inputs
    console.log(formData);
  }

  return (
    <>
      <section className="onbording-section">
        <div className="signup-nav-bar">
          <img
            src={require("../assets/logos/Color=Light 1.png")}
            loading="lazy"
            alt=""
            className="signup-logo"
          />
        </div>
        <div className="sign-up-form-container">
          <div className="w-form">
            <div
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="signup-form"
              data-wf-page-id="6717e3d2e7b2fb4fa2ac38da"
              data-wf-element-id="198cbce3-3680-27fc-5746-166f2df8a8b0"
            >
              <div className="center">
                <div className="signup-progress-group">
                  <div className="code-embed-3 w-embed">
                    <progress
                      id="progress"
                      value={slides[slide].progress}
                      min="0"
                      max="100"
                      style={{ width: "100%", background: "#FFF" }}
                    ></progress>
                  </div>
                  <div className="signup-progress-bar-text-group">
                    <div className="eyebrow s">Step</div>
                    <div className="eyebrow s">
                      {slide + "/" + slides.length}
                    </div>
                  </div>
                </div>
                {/* <div className="header-xl center">
                  Registrations begin in January 2025!
                </div> */}
              </div>
              <div>
                <div className="field-group">
                  {slides[slide].inputs.map((input, index) => {
                    return (
                      <input
                        key={index}
                        style={{ width: "100%" }}
                        className="checkout-field light"
                        maxlength="256"
                        name={input.label}
                        data-name={input.label}
                        placeholder={input.placeholder}
                        type={input.type}
                        id={input.label}
                        onChange={(e) => input.onChange(e)}
                      />
                    );
                  })}
                  <p style={{ color: "red" }}>{warning}</p>
                </div>
                <div className="button-group">
                  {/* <a
                    href="/membership"
                    className="button-primary-light full-width "
                  >
                    Cancel
                  </a> */}

                  <button
                    className="button-dense-primary-dark full-width"
                    onClick={slides[slide].submitButton.onClick}
                  >
                    {slides[slide].submitButton.text}
                  </button>
                </div>
              </div>
              <div className="signup-login-link">
                <p className="body-s">Already have an account? </p>
                <a href="/login" className="link">
                  Log in
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="signup-footer"></div>
      </section>
    </>
  );
}
