import { Dense_button_dark, Dense_Button_Light } from "./buttons.js";
import "../css/medspa-network-v3.webflow.css";
import "../css/webflow.css";
import "../css/normalize.css";
import "../css/hamburger.css";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useShoppingCart } from "../context/ShoppingCartContext.js";

export default function Navbar({ type }) {
  const { pathname } = useLocation();
  const { cartItems, openCart } = useShoppingCart();
  const [learningDropDown, setLearningDropDown] = useState(false);
  const [aboutDropDown, setaboutDropDown] = useState(false);
  const [eventDropDown, setEventDropDown] = useState(false);
  const loggedIn = localStorage.getItem("authToken") ? true : false;
  const navigate = useNavigate();

  function openLearningNav() {
    setLearningDropDown(true);
  }

  function closeLearningNav() {
    setLearningDropDown(false);
  }

  function openAboutNav() {
    setaboutDropDown(true);
  }

  function closeAboutNav() {
    setaboutDropDown(false);
  }

  function openEventNav() {
    setEventDropDown(true);
  }

  function closeEventNav() {
    setEventDropDown(false);
  }

  function toggleMobileNav() {
    const icon = document.getElementById("hamburger");

    if (icon.classList.contains("open")) {
      closeMobileNavKnowledge();
      closeMobileNavAbout();

      const mobileNav = document.getElementById("mobileNav");
      mobileNav.style.transform = "translateX(100vw)";
      mobileNav.style.opacity = 0;

      const spans = document.querySelectorAll("#hamburger span");
      spans.forEach((span) => {
        if (type == "dark") {
          span.style.backgroundColor = "white";
        } else {
          span.style.backgroundColor = "black";
        }
      });

      icon.classList.toggle("open");
    } else {
      const mobileNav = document.getElementById("mobileNav");
      mobileNav.style.transform = "translateX(0px)";
      mobileNav.style.opacity = 100;

      const spans = document.querySelectorAll("#hamburger span");
      spans.forEach((span) => {
        span.style.backgroundColor = "black";
      });

      icon.classList.toggle("open");
    }
  }

  function toggleMobileNavKnowledge() {
    const nav = document.getElementById("mobileNavKnowledge");

    if (nav.classList.contains("open")) {
      nav.style.transform = "translateX(100vw)";
      nav.style.opacity = 0;
      nav.classList.toggle("open");
    } else {
      nav.style.transform = "translateX(0px)";
      nav.style.opacity = 100;
      nav.classList.toggle("open");
    }
  }

  function closeMobileNavKnowledge() {
    const nav = document.getElementById("mobileNavKnowledge");

    nav.style.transform = "translateX(100vw)";
    nav.style.opacity = 0;
    nav.classList.remove("open");
  }

  function toggleMobileNavAbout() {
    const nav = document.getElementById("mobileNavAbout");

    if (nav.classList.contains("open")) {
      nav.style.transform = "translateX(100vw)";
      nav.style.opacity = 0;
      nav.classList.toggle("open");
    } else {
      nav.style.transform = "translateX(0px)";
      nav.style.opacity = 100;
      nav.classList.toggle("open");
    }
  }

  function closeMobileNavAbout() {
    const nav = document.getElementById("mobileNavAbout");

    nav.style.transform = "translateX(100vw)";
    nav.style.opacity = 0;
    nav.classList.remove("open");
  }

  function toggleMobileNavAccount() {
    const nav = document.getElementById("mobileNavAccount");

    if (nav.classList.contains("open")) {
      nav.style.transform = "translateX(100vw)";
      nav.style.opacity = 0;
      nav.classList.toggle("open");
    } else {
      nav.style.transform = "translateX(0px)";
      nav.style.opacity = 100;
      nav.classList.toggle("open");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <nav
      role="navigation"
      className={type == "dark" ? "nav-bar" : "light-nav-bar"}
    >
      <main className="w-layout-blockcontainer navbar-container hide-mobile">
        <div className="div-block-33">
          <a
            href="/"
            aria-current="page"
            className="navbar-logo-container w--current"
          >
            <img
              src={
                type == "dark"
                  ? require("../assets/logos/Color=Dark.png")
                  : require("../assets/logos/Color=Light 1.png")
              }
              loading="lazy"
              alt=""
              className="image-10"
              style={{ objectFit: "contain" }}
            />
          </a>
          <div className="div-block-8">
            <div
              className="drop-down-fix"
              data-hover="false"
              data-delay="0"
              id="learningDropLink"
              onMouseEnter={() => openLearningNav()}
              onMouseLeave={() => closeLearningNav()}
            >
              <div className="nav-link w-dropdown-toggle">
                <div className={type == "dark" ? "nav-link" : "light-nav-link"}>
                  Learning
                </div>
                <div className="icon w-icon-dropdown-toggle nav-link"></div>
              </div>
              <nav
                className={
                  learningDropDown
                    ? "w-dropdown-list w--open dropdown-list"
                    : "w-dropdown-list  dropdown-list"
                }
                style={{
                  maxWidth: "160px !important",
                  width: "160px !important",
                  color: "white",
                }}
                id="eventsDropDown"
              >
                <a
                  href="/online-courses"
                  className={
                    type == "dark"
                      ? "w-dropdown-link dropdown-link"
                      : "w-dropdown-link dropdown-link light-nav-link"
                  }
                  style={{ maxWidth: 150, width: 150, color: "white" }}
                >
                  Online Courses
                </a>
                {/* <a
                  href="/in-person-learning"
                  className={
                    type == "dark"
                      ? "w-dropdown-link dropdown-link"
                      : "w-dropdown-link dropdown-link light-nav-link"
                  }
                  style={{ maxWidth: 150, width: 150, color: "white" }}
                >
                  In-Person
                </a> */}
                <a
                  href="/webinars"
                  className={
                    type == "dark"
                      ? "w-dropdown-link dropdown-link"
                      : "w-dropdown-link dropdown-link light-nav-link"
                  }
                  style={{ maxWidth: 150, width: 150, color: "white" }}
                >
                  Webinars
                </a>
                <a
                  href="/masterclass/february2025"
                  className={
                    type == "dark"
                      ? "w-dropdown-link dropdown-link"
                      : "w-dropdown-link dropdown-link light-nav-link"
                  }
                  style={{ maxWidth: 150, width: 150, color: "white" }}
                >
                  Masterclass
                </a>
              </nav>
            </div>
            {/* <div
              data-hover="false"
              data-delay="0"
              id="eventsDropLink"
              onMouseEnter={() => openEventNav()}
              onMouseLeave={() => closeEventNav()}
            >
              <div className="nav-link w-dropdown-toggle">
                <div className={type == "dark" ? "nav-link" : "light-nav-link"}>
                  Events
                </div>
                <div className="icon w-icon-dropdown-toggle nav-link"></div>
              </div>
              <nav
                className={
                  eventDropDown
                    ? "w-dropdown-list w--open dropdown-list"
                    : "w-dropdown-list  dropdown-list"
                }
                style={{
                  maxWidth: "200px !important",
                  width: "200px !important",
                  color: "white",
                }}
                id="eventsDropDown"
              >
                <a
                  href="/training-events"
                  className={
                    type == "dark"
                      ? "w-dropdown-link dropdown-link"
                      : "w-dropdown-link dropdown-link light-nav-link"
                  }
                  style={{ maxWidth: 200, width: 200, color: "white" }}
                >
                  Off-Label Training
                </a>
                <a
                  href="/society-events"
                  className={
                    type == "dark"
                      ? "w-dropdown-link dropdown-link"
                      : "w-dropdown-link dropdown-link light-nav-link"
                  }
                  style={{ maxWidth: 200, width: 200, color: "white" }}
                >
                  Society
                </a>
                <a
                  href="/industry-events"
                  className={
                    type == "dark"
                      ? "w-dropdown-link dropdown-link"
                      : "w-dropdown-link dropdown-link light-nav-link"
                  }
                  style={{ maxWidth: 200, width: 200, color: "white" }}
                >
                  Industry
                </a>
              </nav>
            </div> */}
            <a
              href="/industry-partners"
              className={type == "dark" ? "nav-link" : "light-nav-link"}
            >
              Our Partners
            </a>
            <div
              data-hover="true"
              data-delay="0"
              id="aboutDropLink"
              onMouseEnter={() => openAboutNav()}
              onMouseLeave={() => closeAboutNav()}
            >
              <div className="nav-link w-dropdown-toggle ">
                <div className={type == "dark" ? "nav-link" : "light-nav-link"}>
                  About
                </div>
                <div className="icon w-icon-dropdown-toggle nav-link"></div>
              </div>
              <nav
                className={
                  aboutDropDown
                    ? "w-dropdown-list w--open dropdown-list"
                    : "w-dropdown-list dropdown-list"
                }
                style={{ maxWidth: 150, width: 150, color: "white" }}
                id="aboutDropDown"
              >
                <a
                  href="/about"
                  className="w-dropdown-link dropdown-link"
                  style={{ maxWidth: 150, width: 150, color: "white" }}
                >
                  About Us
                </a>
                <a
                  href="/team"
                  className="w-dropdown-link dropdown-link"
                  style={{ maxWidth: 150, width: 150, color: "white" }}
                >
                  Team
                </a>
                <a
                  href="/contact"
                  className="w-dropdown-link dropdown-link"
                  style={{ maxWidth: 150, width: 150, color: "white" }}
                >
                  Contact Us
                </a>
              </nav>
            </div>

            <a
              href="/blogs"
              className={type == "dark" ? "nav-link" : "light-nav-link"}
            >
              Blogs
            </a>
            <a
              href="/shop"
              className={type == "dark" ? "nav-link" : "light-nav-link"}
            >
              Shop
            </a>
          </div>
        </div>

        {loggedIn ? (
          <div className="div-block-9">
            <Dense_Button_Light link="/account" text="Account" />

            {cartItems.length > 0 ? (
              <button
                className={type == "dark" ? "nav-link" : "light-nav-link"}
                onClick={() => {
                  navigate("/cart");
                }}
                style={{ position: "relative" }}
              >
                <p
                  style={{
                    fontSize: 12,
                    backgroundColor: "red",
                    borderRadius: 500,
                    width: 20,
                    height: 20,
                    textAlign: "center",
                    color: "white",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    top: -10,
                    right: -10,
                  }}
                >
                  {cartItems.length}
                </p>
                Cart
              </button>
            ) : null}
          </div>
        ) : (
          <div className="div-block-9">
            <Dense_Button_Light link="/membership" text="Join Us" />
            <a
              href="/login"
              className={type == "dark" ? "nav-link" : "light-nav-link"}
            >
              Login
            </a>
          </div>
        )}
      </main>

      <div className="hide-desktop">
        <img
          src={
            type == "dark"
              ? require("../assets/logos/Color=Dark.png")
              : require("../assets/logos/Color=Light 1.png")
          }
          loading="lazy"
          alt=""
          className="navbar-logo-container"
          onClick={() => {
            navigate("/");
          }}
        />
        <button
          id="hamburger"
          className="icon nav-icon-5"
          style={{ zIndex: 9999 }}
          onClick={() => {
            toggleMobileNav();
          }}
        >
          <span
            style={{ backgroundColor: type == "dark" ? "white" : "black" }}
          ></span>
          <span
            style={{ backgroundColor: type == "dark" ? "white" : "black" }}
          ></span>
          <span
            style={{ backgroundColor: type == "dark" ? "white" : "black" }}
          ></span>
        </button>
      </div>
      <div
        className="mobile-nav-container hide-desktop"
        id="mobileNav"
        style={{
          background: "white",
          zIndex: 888,
          transform: "translateX(100vw)",
          opacity: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <div className="div-block-57">
          <div className="div-block-58">
            <div className="mobile-nav-link-container">
              <a
                href="#"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavKnowledge();
                }}
              >
                <div className="button m dark">Knowledge</div>
                <img
                  src={require("../assets/icons/next-1.png")}
                  loading="lazy"
                  alt=""
                  className="small-icon"
                />
              </a>
              <a
                href="#"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAbout();
                }}
              >
                <div className="button m dark">About</div>
                <img
                  src={require("../assets/icons/next-1.png")}
                  loading="lazy"
                  alt=""
                  className="small-icon"
                />
              </a>
              <a href="#" className="mobile-nav-link ">
                <div className="button m dark">Our Partners</div>
              </a>

              <a href="/shop" className="mobile-nav-link ">
                <div className="button m dark">Shop</div>
              </a>
              <a
                href="#"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAccount();
                }}
              >
                <div className="button m dark">Account</div>
                <img
                  src={require("../assets/icons/next-1.png")}
                  loading="lazy"
                  alt=""
                  className="small-icon"
                />
              </a>
            </div>

            <div>
              <a href="#" className="mobile-nav-link ">
                <div className="button m dark">Cart ({cartItems.length})</div>
              </a>
            </div>
          </div>
          <div className="button-group center vertical">
            {loggedIn ? (
              <>
                <a
                  href="/account"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  My Account
                </a>
                <a
                  href="/logout"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Logout
                </a>
              </>
            ) : (
              <>
                <a
                  href="/membership"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Get Membership
                </a>
                <a
                  href="/login"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Login
                </a>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Knowledge Slider */}
      <div
        className="mobile-nav-container hide-desktop"
        id="mobileNavKnowledge"
        style={{
          background: "white",
          zIndex: 999,
          transform: "translateX(100vw)",
          opacity: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <div className="div-block-57">
          <div
            style={{
              position: "absolute",
              left: 20,
              top: 20,
              width: 30,
              height: 30,
            }}
          >
            <img
              src={require("../assets/icons/back.png")}
              loading="lazy"
              alt=""
              onClick={() => {
                toggleMobileNavKnowledge();
              }}
            />
          </div>
          <div className="div-block-58">
            <div className="mobile-nav-link-container">
              <a
                href="/online-courses"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavKnowledge();
                }}
              >
                <div className="button m dark">Online Courses</div>
              </a>
              {/* <a
                href="/in-person"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavKnowledge();
                }}
              >
                <div className="button m dark">In-Person Courses</div>
              </a> */}
              <a
                href="/webinars"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavKnowledge();
                }}
              >
                <div className="button m dark">Webinars</div>
              </a>
              <a
                href="/masterclass/february2025"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavKnowledge();
                }}
              >
                <div className="button m dark">Masterclass</div>
              </a>
            </div>
          </div>
          <div className="button-group center vertical">
            {loggedIn ? (
              <>
                <a
                  href="/account"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                    toggleMobileNavKnowledge();
                    toggleMobileNavAbout();
                  }}
                >
                  My Account
                </a>
                <a
                  href="/logout"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                    toggleMobileNavKnowledge();
                    toggleMobileNavAbout();
                  }}
                >
                  Logout
                </a>
              </>
            ) : (
              <>
                <a
                  href="/membership"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Get Membership
                </a>
                <a
                  href="/login"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Login
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      {/* About Slider */}
      <div
        className="mobile-nav-container hide-desktop"
        id="mobileNavAbout"
        style={{
          background: "white",
          zIndex: 999,
          transform: "translateX(100vw)",
          opacity: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <div className="div-block-57">
          <div
            style={{
              position: "absolute",
              left: 20,
              top: 20,
              width: 30,
              height: 30,
            }}
          >
            <img
              src={require("../assets/icons/back.png")}
              loading="lazy"
              alt=""
              onClick={() => {
                toggleMobileNavAbout();
              }}
            />
          </div>
          <div className="div-block-58">
            <div className="mobile-nav-link-container">
              <a
                href="/about"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAbout();
                }}
              >
                <div className="button m dark">About Us</div>
              </a>
              <a
                href="team"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAbout();
                }}
              >
                <div className="button m dark">Team</div>
              </a>
              <a
                href="/contact"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAbout();
                }}
              >
                <div className="button m dark">Contact</div>
              </a>
            </div>
          </div>
          <div className="button-group center vertical">
            {loggedIn ? (
              <>
                <a
                  href="/account"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                    closeMobileNavKnowledge();
                    closeMobileNavAbout();
                  }}
                >
                  My Account
                </a>
                <a
                  href="/logout"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                    closeMobileNavKnowledge();
                    closeMobileNavAbout();
                  }}
                >
                  Logout
                </a>
              </>
            ) : (
              <>
                <a
                  href="/membership"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Get Membership
                </a>
                <a
                  href="/login"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Login
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Account Slider */}
      <div
        className="mobile-nav-container hide-desktop"
        id="mobileNavAccount"
        style={{
          background: "white",
          zIndex: 999,
          transform: "translateX(100vw)",
          opacity: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <div className="div-block-57">
          <div
            style={{
              position: "absolute",
              left: 20,
              top: 20,
              width: 30,
              height: 30,
            }}
          >
            <img
              src={require("../assets/icons/back.png")}
              loading="lazy"
              alt=""
              onClick={() => {
                toggleMobileNavAccount();
              }}
            />
          </div>
          <div className="div-block-58">
            <div className="mobile-nav-link-container">
              <a
                href="/account"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAccount();
                }}
              >
                <div className="button m dark">My Account</div>
              </a>
              <a
                href="/certificates"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAccount();
                }}
              >
                <div className="button m dark">Certificates</div>
              </a>
              <a
                href="/my-membership"
                className="mobile-nav-link "
                onClick={() => {
                  toggleMobileNavAccount();
                }}
              >
                <div className="button m dark">Membership</div>
              </a>
            </div>
          </div>
          <div className="button-group center vertical">
            {loggedIn ? (
              <>
                <a
                  href="/account"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                    toggleMobileNavKnowledge();
                    toggleMobileNavAbout();
                    toggleMobileNavAccount();
                  }}
                >
                  My Account
                </a>
                <a
                  href="/logout"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                    toggleMobileNavKnowledge();
                    toggleMobileNavAbout();
                    toggleMobileNavAccount();
                  }}
                >
                  Logout
                </a>
              </>
            ) : (
              <>
                <a
                  href="/membership"
                  className="button-dense-primary-dark full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Get Membership
                </a>
                <a
                  href="/login"
                  className="button-dense-primary-light full-width"
                  onClick={() => {
                    toggleMobileNav();
                  }}
                >
                  Login
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
