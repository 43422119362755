import Navbar from "../components/navigations";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import { GetProductById } from "../services/products";
import { useShoppingCart } from "../context/ShoppingCartContext";
import { useNavigate } from "react-router-dom";
import {
  Dense_Button_Light,
  Dense_button_dark,
  Primary_button_dark,
  Primary_button_light,
  Medium_button_secondary_light,
} from "../components/buttons";

export default function CrownAffiliateProduct() {
  const [product, setProduct] = useState({});
  const navigate = useNavigate();

  const { removeFromCart, getItemQuantity, cartItems, addToCart } =
    useShoppingCart();

  async function getInitialData() {
    const _id = window.location.pathname.split("/").pop();
    const product = await GetProductById(_id);
    const quantity = getItemQuantity(_id);
    console.log(product);
    setProduct({
      ...product,
      quantity: quantity,
      company: product.image.split("/")[1],
      file: product.image.split("/")[2],
      fileName: product.image.split("/")[2].split(".")[0],
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getInitialData();
    console.log(cartItems);
  }, [cartItems]);

  return (
    <>
      <Navbar />
      <section className="lightgrey-background-section">
        <div className="w-layout-blockcontainer container w-container">
          <div
            id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933613-cb120944"
            className="w-layout-layout double-stack wf-layout-layout"
          >
            <div
              id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933614-cb120944"
              className="w-layout-cell"
            >
              <img
                src={
                  "/content/product-images/" +
                  product.company +
                  "/resized/" +
                  product.fileName +
                  ".webp"
                }
                loading="lazy"
                alt=""
                className="product-image"
              />
            </div>
            <div
              id="w-node-_54e7edc1-5170-ad65-a5dc-43245f933615-cb120944"
              className="w-layout-cell cell-14"
            >
              <div className="header-l">{product.title}</div>
              <p className="body-s">{product.description}</p>
              <div className="header-s">
                {product.price?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </div>

              <a
                className="button-dense-primary-dark"
                text="New Account"
                href="/content/product-images/crown/New Account Order Form.pdf"
                target="_blank"
              >
                New Crown Aesthetics Account Signup
              </a>
              <div>
                <p className="body-s">
                  New accounts will need to fill out the form above and submit
                  it to info@medspanetwork.com.
                </p>
              </div>
              <p className="body-s">
                If you already have a Crown Aesthetics account, please proceed
                to the order form and submit it to info@medspanetwork.com
              </p>
              <a
                className="button-dense-primary-light"
                text="Order Form"
                href="/content/product-images/crown/New Account Order Form.pdf"
                target="_blank"
              >
                Order Form
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
